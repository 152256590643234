import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  padding: 40px;
  margin-top: 56px;
  background-color: #3a3c62;
  align-items: center;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};

  .cta {
    justify-self: end;
  }

  .btn {
    padding: 16px 40px;
    background-color: ${props => props.theme.colors.primary};
    color: white;
    font-weight: bold;
    border: 2px solid transparent;
    text-decoration: none;
    display: block;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: #334bd6;
    }
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
    padding: 24px;

    .cta {
      justify-self: start;
      width: 100%;
      text-align: center;
    }
  }
`

const BecomePartner = ({ title, description, cta }) => {
  return (
    <StyledWrapper>
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="cta">
        <a className="btn" href="mailto:info@fgcharts.com">
          {cta}
        </a>
      </div>
    </StyledWrapper>
  )
}

export default BecomePartner
