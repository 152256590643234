import React from "react"
import { graphql } from "gatsby"
import MarkdownView from "react-showdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PartnersList from "../components/partnersList/index"
import BecomePartner from "../components/partnersList/becomePartner"
import { PostContainer, PostHeader } from "../components/styled/post.js"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const Partners = ({ data }) => {
  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <Container main style={{ flex: "10 0 auto" }}>
          <SEO
            title="Partners - FGCharts"
            description={data.strapiSeo.partnerspage}
          />
          <PostHeader>
            <h2 style={{ marginBottom: 16 }}>FGCharts Partners</h2>
          </PostHeader>
          <PostContainer>
            <MarkdownView
              markdown={data.strapiPartners.Partners}
              options={{ table: true, emoji: true }}
            />
          </PostContainer>
          <PartnersList
            gold={data.gold}
            platinum={data.platinum}
            diamond={data.diamond}
            sponsor={data.sponsor}
            placeholder={data.placeholder}
          />
          <BecomePartner
            title="Become a FGCharts Partner"
            description={data.strapiPartners.BoxText}
            cta="Become a Partner"
          />
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiSeo {
      partnerspage
    }
    strapiPartners {
      Partners
      BoxText
    }
    placeholder: allStrapiPartnersList(
      filter: { Tier: { eq: "Placeholder" } }
    ) {
      edges {
        node {
          id
          Name
          Description
          Tier
          Url
          Logo {
            publicURL
          }
        }
      }
    }
    gold: allStrapiPartnersList(filter: { Tier: { eq: "Gold" } }) {
      edges {
        node {
          id
          Name
          Description
          Tier
          Url
          Logo {
            publicURL
          }
        }
      }
    }
    platinum: allStrapiPartnersList(filter: { Tier: { eq: "Platinum" } }) {
      edges {
        node {
          id
          Name
          Description
          Tier
          Url
          Logo {
            publicURL
          }
        }
      }
    }
    diamond: allStrapiPartnersList(filter: { Tier: { eq: "Diamond" } }) {
      edges {
        node {
          id
          Name
          Description
          Tier
          Url
          Logo {
            publicURL
          }
        }
      }
    }
    sponsor: allStrapiPartnersList(filter: { Tier: { eq: "Sponsor" } }) {
      edges {
        node {
          id
          Name
          Description
          Tier
          Url
          Logo {
            publicURL
          }
        }
      }
    }
  }
`

export default Partners
