import React from "react"
import styled from "styled-components"

// Assets
import { ArrowStraightIcon } from "../../images/icons"

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const StyledCard = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  background-color: #2c2a34;
  border-bottom: 4px solid
    ${({ tier, theme }) => {
      if (tier === "Sponsor") {
        return theme.colors.green
      } else if (tier === "Platinum") {
        return "#E5E4E2"
      } else if (tier === "Gold") {
        return "#FFCD56"
      } else if (tier === "Diamond") {
        return "#B9F2FF"
      } else {
        return theme.colors.primary
      }
    }};

  .card-content {
    display: grid;
    justify-items: center;
    text-align: center;
  }

  .card-tier {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-family: "Oswald";
    color: ${({ theme, tier }) => {
      if (tier === "Gold") {
        return "#FFCD56"
      } else if (tier === "Diamond") {
        return "#B9F2FF"
      } else if (tier === "Platinum") {
        return "#E5E4E2"
      } else if (tier === "Sponsor") {
        return theme.colors.green
      } else {
        return theme.colors.primary
      }
    }};
  }

  .card-title {
    font-weight: bold;
    font-family: "Oswald";
    font-size: 20px;
  }

  .card-url {
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 14px;

    span {
      margin-right: 8px;
      transition: all 0.25s ease-in-out;
    }

    :hover {
      span {
        margin-right: 12px;
      }
    }
  }
`

const PartnersList = ({ placeholder, gold, platinum, sponsor, diamond }) => {
  return (
    <div>
      {sponsor.edges.length ? (
        <>
          <h3 style={{ marginBottom: 16, textAlign: "center" }}>Sponsors</h3>
          <StyledGrid>
            {sponsor.edges.map(({ node }) => {
              return (
                <StyledCard key={node.id} tier={node.Tier}>
                  <div className="card-content">
                    {node.Logo && (
                      <img
                        src={node.Logo.publicURL}
                        style={{ maxHeight: 40 }}
                      />
                    )}
                    <p className="card-title">{node.Name}</p>
                    <p className="card-description">{node.Description}</p>
                    <a className="card-url" href={node.Url} target="_blank">
                      <span>{node.ButtonText}</span>
                      <ArrowStraightIcon />
                    </a>
                  </div>
                </StyledCard>
              )
            })}
          </StyledGrid>
        </>
      ) : null}
      {diamond.edges.length ? (
        <>
          <h3 style={{ marginBottom: 16, textAlign: "center", marginTop: 32 }}>
            Diamond
          </h3>
          <StyledGrid>
            {diamond.edges.map(({ node }) => {
              return (
                <StyledCard key={node.id} tier={node.Tier}>
                  <div className="card-content">
                    {node.Logo && (
                      <img
                        src={node.Logo.publicURL}
                        style={{ maxHeight: 40 }}
                      />
                    )}
                    <p className="card-title">{node.Name}</p>
                    <p className="card-description">{node.Description}</p>
                    <a className="card-url" href={node.Url} target="_blank">
                      <span>{node.ButtonText}</span>
                      <ArrowStraightIcon />
                    </a>
                  </div>
                </StyledCard>
              )
            })}
          </StyledGrid>
        </>
      ) : null}
      {platinum.edges.length ? (
        <>
          <h3 style={{ marginBottom: 16, marginTop: 32, textAlign: "center" }}>
            Platinum
          </h3>
          <StyledGrid>
            {platinum.edges.map(({ node }) => {
              return (
                <StyledCard key={node.id} tier={node.Tier}>
                  <div className="card-content">
                    {node.Logo && (
                      <img
                        src={node.Logo.publicURL}
                        style={{ maxHeight: 40 }}
                      />
                    )}
                    <p className="card-title">{node.Name}</p>
                    <p className="card-description">{node.Description}</p>
                    <a className="card-url" href={node.Url} target="_blank">
                      <span>{node.ButtonText}</span>
                      <ArrowStraightIcon />
                    </a>
                  </div>
                </StyledCard>
              )
            })}
          </StyledGrid>
        </>
      ) : null}
      {gold.edges.length ? (
        <>
          <h3 style={{ marginBottom: 16, marginTop: 32, textAlign: "center" }}>
            Gold
          </h3>
          <StyledGrid>
            {gold.edges.map(({ node }) => {
              return (
                <StyledCard key={node.id} tier={node.Tier}>
                  <div className="card-content">
                    {node.Logo && (
                      <img
                        src={node.Logo.publicURL}
                        style={{ maxHeight: 40 }}
                      />
                    )}
                    <p className="card-title">{node.Name}</p>
                    <p className="card-description">{node.Description}</p>
                    <a className="card-url" href={node.Url} target="_blank">
                      <span>{node.ButtonText}</span>
                      <ArrowStraightIcon />
                    </a>
                  </div>
                </StyledCard>
              )
            })}
          </StyledGrid>
        </>
      ) : null}
    </div>
  )
}

export default PartnersList
